import { graphql, Link } from 'gatsby'; 
import DangerousHTML from 'react-dangerous-html';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '../components/GridList'; 
import Layout from '../components/layout'; 
import HubspotForm from '../components/hubspot-form'; 
import './home.css'; 

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

// const SkillDisplay = ({ isMobile, title, firstP, secondP, image, index }) => {
//   if (isMobile) {
//     return (
//       <div style={{display: 'flex'}}>
//         <div className='skillDisplayText' style={{position: 'absolute', zIndex: 1, width: '100%', textAlign: 'center', fontSize: '1.25em'}}>
//           <h3 style={{textAlign: 'center'}}>
//             { title }
//             <span style={{width: 50, height: 4, margin: '8px auto 0', display: 'block'}}></span>
//           </h3>
//           <p>{ firstP }</p>
//           <p>{ secondP }</p>
//         </div>
//         <div style={{backgroundImage: `url(${image})`, opacity: 0.25, width: '100%'}} className='skillDisplayImage' />
//       </div>
//     )
//   }

  // if (index % 2 === 0) {
  //   return (
  //     <div style={{display: 'flex'}}>
  //       <Slide left cascade>
  //         <div className='skillDisplayText'>
  //           <h3 style={{textAlign: 'center'}}>
  //             { title }
  //             <span style={{width: 50, height: 4, margin: '8px auto 0', display: 'block'}}></span>
  //           </h3>
  //           <p>{ firstP }</p>
  //           <p>{ secondP }</p>
  //         </div>
  //       </Slide>
  //       <Slide right>
  //         <div style={{backgroundImage: `url(${image})`}} className='skillDisplayImage' />
  //       </Slide>
  //     </div>
  //   )
  // } else {
  //   return (
  //     <div style={{display: 'flex'}}>
  //       <Slide left>
  //         <div style={{backgroundImage: `url(${image})`}} className='skillDisplayImage' />
  //       </Slide>
  //       <Slide right cascade>
  //         <div className='skillDisplayText'>
  //           <h3 style={{textAlign: 'center'}}>
  //             { title }
  //             <span style={{width: 50, height: 4, margin: '8px auto 0', display: 'block'}}></span>
  //           </h3>
  //           <p>{ firstP }</p>
  //           <p>{ secondP }</p>
  //         </div>
  //       </Slide>
  //     </div>
  //   )
  // }
// }

const HeaderTitle = (props) => {
  return (
    // <StaticQuery query={graphql`
    //   query {
    //     file (extension: {eq: "jpg"}) {
    //       childImageSharp {
    //         fluid(quality: 100, maxWidth: 4160) {
    //           ...GatsbyImageSharpFluid_withWebp
    //         }
    //       }
    //     }
    //   }
    // `}
    // render={data => {
    //   // Set ImageData.
    //   const imageData = data.file.childImageSharp.fluid
    //   return (
    //       <header id='homeHeader'>
    //         <div id='headerImage' />
    //         <div data-aos='fade-in'>
    //           <BackgroundImage
    //             fluid={imageData}
    //             backgroundColor={'#292929'}
    //             style={{height: '100vh', width: '100vw', opacity: 0.25}}
    //           />
    //           <h1>Toni's Gardiner</h1>
    //           <h2>Salg og Montage</h2>
    //         </div>
    //       </header>
    //   )
    // }
    // }
    // />
    <header id='homeHeader'>
      <div id='headerImage' />
      <div data-aos='fade-in'>
        {/* <BackgroundImage
          fluid={imageData}
          backgroundColor={'#292929'}
          style={{height: '100vh', width: '100vw', opacity: 0.25}}
        /> */}
        <div style={{height: '100vh', width: '100vw', opacity: 0.25, backgroundImage: `url(${props.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
        <h1>Toni's Gardiner</h1>
        <h2>Salg og Montage</h2>
      </div>
    </header>
  )
}


class Home extends Component {
  state = { windowWidth: 0 }
  componentDidMount() {
    this.setState({ windowWidth: window.innerWidth })
  }
  render() {
    const { classes, data } = this.props;
    const { meta, image } = data.markdownRemark.frontmatter; 
    const { edges } = data.allMarkdownRemark; 
    return (
      <Layout meta={meta}>
        <HeaderTitle image={image} /> 
        <main>
        {/* 
          <section id='skillDisplay'>
            {
              skillDisplayData.map((skill, i) => {
                return (
                  <SkillDisplay
                    key={i}
                    isMobile={isMobile}
                    title={skill.title}
                    firstP={skill.firstP}
                    secondP={skill.secondP}
                    image={skill.image}
                    index={i}
                  />
                )
              })
            }
          </section> */}
          <section id='productDisplay'>
            <div data-aos='fade-in'>
              <h2>
                Stort Sortiment af Gardiner og Markiser
                <span style={{width: 75, height: 4, margin: '8px auto 0', display: 'block', backgroundColor: '#29699B'}}></span>
              </h2>
            </div>
            <div className={classes.root}>
              <GridList productsData={edges} windowWidth={this.state.windowWidth} />
            </div>
            <div style={{marginBottom: 50}}>
              <h3>Se hele udvalget:</h3>
              <Link style={{color: '#02afaf'}} to='/produkter'>Produkter</Link>
            </div>
          </section>
          <section id='contactSection'>
            <div>
              <h2>
                Kontakt
                <span style={{width: 75, height: 4, margin: '8px auto 0', display: 'block', backgroundColor: '#29699B'}}></span>
              </h2>
            </div>
            <div className='contact-content page-content'>
              {data.contactMarkdown.html && <DangerousHTML html={data.contactMarkdown.html} />}
              <div className='vertical-rule' />
              <HubspotForm 
                portalId={data.contactMarkdown.frontmatter.hubspotForm.portalId}
                formId={data.contactMarkdown.frontmatter.hubspotForm.formId}
              />
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    markdownRemark(frontmatter: {title: {eq: "Landing"}}) {
    	frontmatter{
        title
        image
        hubspotForm {
          portalId
          formId
        }
        meta {
          title
          description
          keywords
          robots
        }
      }
    }
    contactMarkdown: markdownRemark(frontmatter: {title: {eq: "Kontakt"}}) {
    	frontmatter{
        hubspotForm {
          portalId
          formId
        }
        meta {
          title
          description
          keywords
          robots
        }
      }
      html
    }
    allMarkdownRemark (
      filter: {
        frontmatter: {path: {regex: "//produkter//"}} 
      }
      sort: {
        fields: frontmatter___title, order: ASC
      }
      limit: 7
    ) {
    	edges {
        node {
          id
          frontmatter {
            path
            image
            title
            cols
          }
        }
      }
  	}
  }
`

export default withStyles(styles)(Home);


