import React from 'react';
import './styles.css'; 

export default class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: this.props.portalId,
          formId: this.props.formId,
          target: '#hubspot-form'
        })
      }
    });
  }
  
    render() {
        return (
          <div id='hubspot-form-container'> 
              <div id="hubspot-form" />
          </div>
        );
    }
}